import { graphql } from 'gatsby';
import get from 'lodash/get';
import React from 'react';

import { siteMetadata } from '../../gatsby-config';
import Layout from 'components/Layout';
import Meta from 'components/Meta';
import HeaderImage from 'components/HeaderImage';
import ContactForm from 'components/ContactForm';

const ContactPage = ({ location, data }) => {
  const header = get(data, 'header.childImageSharp.fluid');

  return (
    <Layout location={location}>
      <Meta site={siteMetadata} title="Contact Us" />
      <HeaderImage image={header}>
        <h1>Contact Us</h1>
      </HeaderImage>

      <p>
        We do individualized work with each of our clients. If you're interested
        in working with us, send us a message here to let us know how we might
        be able to help.
      </p>
      <p>We'll get back to you by email as soon as possible.</p>

      <ContactForm />
    </Layout>
  );
};

export default ContactPage;

export const query = graphql`
  query ContactPageQuery {
    header: file(name: { eq: "joanna-kosinska-439234-unsplash" }) {
      childImageSharp {
        fluid(maxWidth: 800, grayscale: true) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
