import React from 'react';

import './style.scss';

// If it's got an @ in the middle and stuff before and after, the odds are very good that it's a
// valid email address. See https://hackernoon.com/the-100-correct-way-to-validate-email-addresses-7c4818f24643
// for some "fun" examples.
const validEmailRegex = /.+@.+/;

const apiURL =
  'https://cmpqgrfgw4.execute-api.us-west-2.amazonaws.com/prod/contact';

class ContactForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: '',
      email: '',
      message: '',
      status: 'COMPOSING',
      statusMessage: null,
    };
  }

  updateName = event => this.setState({ name: event.target.value });
  updateEmail = event => this.setState({ email: event.target.value });
  updateMessage = event => this.setState({ message: event.target.value });

  handleSubmit = event => {
    this.sendMessage();
    event.preventDefault();
  };

  readyToSend = () =>
    this.state.name != '' &&
    this.state.email.match(validEmailRegex) &&
    this.state.message != '' &&
    this.state.status != 'IN PROGRESS';

  sendMessage = async () => {
    const { name, email, message } = this.state;
    const body = JSON.stringify({ name, email, message });
    const method = 'POST';
    const headers = { 'Content-Type': 'application/json' };
    try {
      this.setState({ status: 'IN PROGRESS' });
      const response = await fetch(apiURL, { method, body, headers });

      if (response.ok) {
        this.setState({
          status: 'SUCCESS',
          statusMessage:
            'Your message has been sent! Check your email for a copy.',
        });
      } else {
        this.setState({
          status: 'ERROR',
          statusMessage: `Something went wrong trying to send. Got ${
            response.status
          } (${response.statusText})`,
        });
      }
    } catch (error) {
      this.setState({
        status: 'ERROR',
        statusMessage: `Something went wrong trying to send: ${error.message}`,
      });
    }
  };

  getStatus = () => {
    switch (this.state.status) {
      case 'COMPOSING':
        return (
          <div className="status">
            {this.readyToSend() ? null : 'All fields are required.'}
          </div>
        );
      case 'IN PROGRESS':
        return <div className="status" />;
      case 'SUCCESS':
        return <div className="status success">{this.state.statusMessage}</div>;
      case 'ERROR':
        return <div className="status error">{this.state.statusMessage}</div>;
    }
  };

  render() {
    return (
      <form className="contact-form" onSubmit={this.handleSubmit}>
        <label htmlFor="name">Your name</label>
        <input
          type="text"
          className="form-control"
          id="name"
          value={this.state.name}
          onChange={this.updateName}
        />

        <label htmlFor="email">Your email address</label>
        <input
          type="text"
          className="form-control"
          id="name"
          value={this.state.email}
          onChange={this.updateEmail}
        />

        <label htmlFor="message">How can we help you?</label>
        <textarea
          className="form-control"
          id="message"
          value={this.state.message}
          onChange={this.updateMessage}
        />

        <button className="btn btn-primary" disabled={!this.readyToSend()}>
          Send message
        </button>

        {this.getStatus()}
      </form>
    );
  }
}

export default ContactForm;
